import { FunctionComponent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import HelpSection from "../../HelpSection/HelpSection";
import history from "@/Utils/history";

export interface AppBarProps {
  dark?: boolean;
  title: string;
}

const useStyles = makeStyles({
  productHeader: {
    backgroundColor: "#fff",
    padding: "0 1rem",
    height: "3rem",
    flex: "0 0 3rem",
    display: "flex",
    alignItems: "center",
    top: 0,
    width: "100%",
    zIndex: 999,
    boxShadow: "2px 2px 8px 0 rgba(0, 0, 0, 0.4)",
    position: "sticky",
  },
  backIcon: {
    marginRight: "0.5rem",
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
  },
});

export const AppBar: FunctionComponent<AppBarProps> = (props) => {
  const classes = useStyles();

  const onBackButtonClick = () => {
    (window as any).Android?.onNativeBackPress();
    (window as any).ReactNativeWebView?.postMessage(JSON.stringify({ goBack: true }));
  };

  return (
    <div className={classes.productHeader} style={props.dark ? { backgroundColor: "#000" } : {}}>
      <span className={classes.backIcon} onClick={onBackButtonClick}>
        <ArrowBackIcon />
      </span>
      <label
        style={{
          position: "absolute",
          fontWeight: 700,
          transform: "translateX(-50%)",
          left: "50%",
          top: "9px",
          fontSize: "20px",
          width: "67%",
        }}
      >
        {props.title}
      </label>
      <div className="flex justify-end w-100">
        <HelpSection />
      </div>
    </div>
  );
};
