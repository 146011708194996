import * as storeTypes from "./../ActionTypes";

interface Action {
  type: string;
  data?: any;
  callback?: any;
  actionBool?: any;
}

export const saveStoreInfo = (data: any): Action => {
  return {
    type: storeTypes.SAVE_STORE_INFO,
    data,
  };
};

export const setStoreServices = (data: any): Action => {
  return {
    type: storeTypes.SET_STORE_SERVICES,
    data,
  };
};

export const setStoreInvoices = (data: any): Action => {
  return {
    type: storeTypes.SET_STORE_INVOICES,
    data,
  };
};

export const fetchStoreInvoices = (): Action => {
  return {
    type: storeTypes.FETCH_STORE_INVOICES,
  };
};

export const fetchInvoiceinfo = (data: any, callback: any): Action => {
  return {
    type: storeTypes.FETCH_INVOICE_INFO,
    data,
    callback,
  };
};

export const generateInvoice = (data: any, callback: any): Action => {
  return {
    type: storeTypes.GENERATE_INVOICE,
    data,
    callback,
  };
};

export const updateStoreServices = (data: any): Action => {
  return {
    type: storeTypes.UPDATE_STORE_SERVICES,
    data,
  };
};

export const changeStoreName = (
  data: { store_id: number; store_name: string; showSuccessMessage?: boolean },
  callback?: any,
): Action => {
  return {
    type: storeTypes.CHANGE_STORENAME,
    data,
    callback,
  };
};

export const getOtpForDisplayNumber = (data: { display_number: string; callback: any }): Action => {
  return {
    type: storeTypes.GET_DISPLAY_NUMBER_OTP,
    data,
  };
};

export const verifyOTPForDisplayNumber = (data: {
  phone: string;
  otp: number;
  store_id: string;
  callback: any;
}): Action => {
  return {
    type: storeTypes.VERIFY_DISPLAY_NUMBER_OTP,
    data,
  };
};

export const loginAndUpdatePhoneNumber = (data: {
  phone: string;
  otp: number;
  store_id: string;
  callback: any;
}): Action => {
  return {
    type: storeTypes.LOGIN_AND_UPDATE_PHONE,
    data,
  };
};

export const deleteStore = (callback: any, data?: any): Action => {
  return {
    type: storeTypes.DELETE_STORE,
    data,
    callback,
  };
};

export const updateStoreInfo = (data: any): Action => {
  return {
    type: storeTypes.UPDATE_STORE_INFO,
    data,
  };
};

export const saveStoreAddress = (data: any, callback?: any): Action => {
  return {
    type: storeTypes.SAVE_STORE_ADDRESS,
    data,
    callback,
  };
};

export const updateStoreAddress = (data: any): Action => {
  return {
    type: storeTypes.UPDATE_STORE_ADDRESS,
    data,
  };
};

export const setStoreLogo = (data: any) => {
  return {
    type: storeTypes.SET_STORE_LOGO,
    data,
  };
};

export const setStoreDescription = (data: any) => {
  return {
    type: storeTypes.SET_STORE_DESCRIPTION,
    data,
  };
};

export const updateStoreBusiness = (data: any) => {
  return {
    type: storeTypes.UPDATE_STORE_BUSINESS,
    data,
  };
};

export const updateStoreDomain = (data: any, callback: any) => {
  return {
    type: storeTypes.UPDATE_STORE_LINK,
    data,
    callback,
  };
};

export const setKycVerification = (data: any) => {
  return {
    type: storeTypes.SET_KYC_VERIFICATION,
    data,
  };
};

export const captureHypervergeSDKResponse = (data: any) => {
  return {
    type: storeTypes.SET_SDK_RESPONSE,
    data,
  };
};

export const setGST = (data: any) => {
  return {
    type: storeTypes.SET_GST_NUMBER,
    data,
  };
};

export const setDeliveryInfo = (data: any, callback?: any, actionBool?: any): Action => {
  return {
    type: storeTypes.SET_DELIVERY_INFO,
    data,
    callback,
    actionBool,
  };
};

export const setCodConfig = (data: any, callback?: any): Action => {
  return {
    type: storeTypes.SET_COD_CHARGES,
    data,
    callback,
  };
};

export const setServices = (data: any, callback?: any): Action => {
  return {
    type: storeTypes.SET_SERVICES,
    data,
    callback,
  };
};

export const setNotification = (data: any, callback?: any): Action => {
  return {
    type: storeTypes.SET_NOTIFICATIONS,
    data,
    callback,
  };
};

export const getStoreCatalogsToWhatsapp = (data: any, callback?: any): Action => {
  return {
    type: storeTypes.GET_STORE_CATALOGS_TO_WHATSAPP,
    data,
    callback,
  };
};

/**
 * Store User Related Information
 */

export const setStoreUserInfo = (data: any, callback?: any): Action => {
  return {
    type: storeTypes.SET_STORE_USER_INFO,
    data,
    callback,
  };
};

export const getStoreUserInfo = (data: any): Action => {
  return {
    type: storeTypes.GET_STORE_USER_INFO,
    data,
  };
};

export const updateServiceBasedCustomizationFlag = (data: any, callback?: any): Action => {
  return {
    type: storeTypes.UPDATE_SERVICE_BASED_CUSTOMIZATION_FLAG,
    data,
    callback,
  };
};

export const updateStoreAnnouncements = (data: any, callback?: any): Action => {
  return {
    type: storeTypes.UPDATE_STORE_ANNOUNCEMENTS,
    data,
    callback,
  };
};

export const updateStoreSocialMediaDetails = (data: any, callback?: any): Action => {
  return {
    type: storeTypes.UPDATE_STORE_SOCIAL_MEDIA_DETAILS,
    data,
    callback,
  };
};

export const updateStoreType = (data: any, callback?: any): Action => {
  return {
    type: storeTypes.UPDATE_STORE_TYPE,
    data,
    callback,
  };
};

export const setCustomerDetailsForm = (data: any, callback?: any): Action => {
  return {
    type: storeTypes.SET_CUSTOMER_DETAILS_FORM,
    data,
    callback,
  };
};

export const getCustomerDetailsForm = (callback?: any): Action => {
  return {
    type: storeTypes.GET_CUSTOMER_DETAILS_FORM,
    callback,
  };
};

export const saveCustomerDetailsForm = (data: any): Action => {
  return {
    type: storeTypes.SAVE_CUSTOMER_DETAILS_FORM,
    data,
  };
};

export const submitSuggestion = (data: any, callback?: any): Action => {
  return {
    type: storeTypes.SUBMIT_SUGGESTION,
    data,
    callback,
  };
};

export const updateCancellationConfig = (data: any, callback?: any): Action => {
  return {
    type: storeTypes.UPDATE_ORDER_CANCELLATION,
    data,
    callback,
  };
};

export const toggleCustomersLogin = (data: any): Action => {
  return {
    type: storeTypes.TOGGLE_CUSTOMERS_LOGIN,
    data,
  };
};
