export const GET_BUSINESS_CATEGORIES = "BRAND_WEBSITE/GET_BUSINESS_CATEGORIES";
export const SET_BUSINESS_CATEGORIES = "BRAND_WEBSITE/SET_BUSINESS_CATEGORIES";
export const GET_CATEGORY_THEMES = "BRAND_WEBSITE/GET_CATEGORY_THEMES";
export const SET_CATEGORY_THEMES = "BRAND_WEBSITE/SET_CATEGORY_THEMES";
export const GET_PREVIEW_THEME = "BRAND_WEBSITE/GET_PREVIEW_THEME";
export const SET_PREVIEW_THEME = "BRAND_WEBSITE/SET_PREVIEW_THEME";
export const SET_OR_PURCHASE_THEME = "BRAND_WEBSITE/SET_OR_PURCHASE_THEME";
export const EMPTY_CATEGORY_THEMES = "BRAND_WEBSITE/EMPTY_CATEGORY_THEMES";
export const EXPORT_CUSTOMER_ENQUIRY_REPORT = "BRAND_WEBSITE/EXPORT_CUSTOMER_ENQUIRY_REPORT";
export const GET_BRAND_LEADS = "BRAND_WEBSITE/GET_BRAND_LEADS";
export const GET_RENEWAL_PAYMENT_DETAILS = "BRAND_WEBSITE/GET_RENEWAL_PAYMENT_DETAILS";
