export const LOGO_AND_NAME_STORAGE_KEY = "logo_and_name_storage_key";

// these flags are used by the backend to identify what to update
export const UPDATE_FLAG_LOGO_HEADER = 12;
export const UPDATE_FLAG_NAME_HEADER = 13;
export const UPDATE_FLAG_LOGO_FOOTER = 14;
export const UPDATE_FLAG_NAME_FOOTER = 15;
export const UPDATE_FLAG_THEME_COLOR_HEADER = 17;

// the status defines the visibility of logo or name with respect to header and footer
export const STATUS_DISABLED = 0; // when the store is newly created and no values is set
export const STATUS_SHOW_ON_HEADER = 1;
export const STATUS_SHOW_ON_FOOTER = 2;
export const STATUS_SHOW_ON_HEADER_FOOTER = 3;
export const STATUS_DONT_SHOW_ON_HEADER_FOOTER = 4;

export interface PageDataModel {
  name: string;
  value: boolean;
  updateIdentifier: number;
}
export interface ToggleStateModel {
  headerLogoActive: boolean;
  headerNameActive: boolean;
  headerThemeColorActive: boolean;
  footerLogoActive: boolean;
  footerNameActive: boolean;
}

const COMMON_STATUS_SHOW = [STATUS_DISABLED, STATUS_SHOW_ON_HEADER_FOOTER];

export function getVisibilityForHeader(status: number): boolean {
  return [...COMMON_STATUS_SHOW, STATUS_SHOW_ON_HEADER].includes(status);
}

export function getVisibilityForFooter(status: number): boolean {
  return [...COMMON_STATUS_SHOW, STATUS_SHOW_ON_FOOTER].includes(status);
}

export const HEADER_FOOTER_CONFIG_STATUS = {
  THEME_COLOR: "store_theme_color_status",
  LOGO: "store_logo_status",
  NAME: "store_name_status",
};
