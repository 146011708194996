import * as brandWebsiteTypes from "../ActionTypes/brandWebsiteTypes";

export const getBrandWebsiteBusinnessCategories = (callback: any) => {
  return {
    type: brandWebsiteTypes.GET_BUSINESS_CATEGORIES,
    callback,
  };
};

export const setBrandWebsiteBusinnessCategories = (data: any) => {
  return {
    type: brandWebsiteTypes.SET_BUSINESS_CATEGORIES,
    data,
  };
};

export const getBrandWebsiteCategoryThemes = (data: any, callback: any) => {
  return {
    type: brandWebsiteTypes.GET_CATEGORY_THEMES,
    data,
    callback,
  };
};

export const exportCustomerEnquiryReport = (data: any, callback?: any) => {
  return {
    type: brandWebsiteTypes.EXPORT_CUSTOMER_ENQUIRY_REPORT,
    data,
    callback,
  };
};

export const getBrandWebsiteLeads = (data: any, callback?: any) => {
  return {
    type: brandWebsiteTypes.GET_BRAND_LEADS,
    data,
    callback,
  };
};

export const emptyBrandWebsiteCategoryThemes = () => {
  return {
    type: brandWebsiteTypes.EMPTY_CATEGORY_THEMES,
  };
};

export const setBrandWebsiteCategoryThemes = (data: any) => {
  return {
    type: brandWebsiteTypes.SET_CATEGORY_THEMES,
    data,
  };
};

export const getBrandWebsitePreviewTheme = (data: any, callback: any) => {
  return {
    type: brandWebsiteTypes.GET_PREVIEW_THEME,
    data,
    callback,
  };
};

export const setBrandWebsitePreviewTheme = (data: any) => {
  return {
    type: brandWebsiteTypes.SET_PREVIEW_THEME,
    data,
  };
};

export const applyBrandWebsiteTheme = (data: any, callback: any) => {
  return {
    type: brandWebsiteTypes.SET_OR_PURCHASE_THEME,
    data,
    callback,
  };
};

export const getBrandWebsiteRenewalPaymentDetails = (data: any, callback: any) => {
  return {
    type: brandWebsiteTypes.GET_RENEWAL_PAYMENT_DETAILS,
    data,
    callback,
  };
};
