import apiCall from "../../Services/api";
import { put, takeEvery } from "redux-saga/effects";
import { diceLoaderVisibilty } from "../Actions/common";
import { BRAND_WEBSITE_API } from "../../Services/apiUrls";
import {
  EXPORT_CUSTOMER_ENQUIRY_REPORT,
  GET_BRAND_LEADS,
  GET_BUSINESS_CATEGORIES,
  GET_CATEGORY_THEMES,
  GET_PREVIEW_THEME,
  GET_RENEWAL_PAYMENT_DETAILS,
  SET_OR_PURCHASE_THEME,
} from "../ActionTypes/brandWebsiteTypes";
import {
  emptyBrandWebsiteCategoryThemes,
  getBrandWebsiteRenewalPaymentDetails,
  setBrandWebsiteBusinnessCategories,
  setBrandWebsiteCategoryThemes,
  setBrandWebsitePreviewTheme,
} from "../Actions/brandWebsite";
import { ToastNotifyError } from "../../Components/Toast";
import { PREMIUM_PURCHASE_TYPE_CONSTANTS } from "../../Constants";
import { appendCreditUsedKey, setPrePaymentDataToSessionStorage, setPremiumPurchaseType } from "../../Utils/_helper";
import { authenticateUser, savePrePaymentData } from "../Actions";
import LocalStorageHelper from "../../Utils/LocalStorageHelper";

function* fetchBrandWebsiteBusinnessCategories(actions: any) {
  yield put(diceLoaderVisibilty(true));
  try {
    const response = yield apiCall({
      method: "GET",
      url: BRAND_WEBSITE_API.GET_BUSINESS_CATEGORIES,
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      yield put(setBrandWebsiteBusinnessCategories(response.data?.data?.business_types));
    } else if (response && !response?.data?.status) {
      actions?.callback && actions?.callback();
      ToastNotifyError("Unable to fetch categories");
    }
  } catch {
    actions?.callback && actions?.callback();
    yield put(diceLoaderVisibilty(false));
    ToastNotifyError("Unable to fetch categories");
  } finally {
    yield put(diceLoaderVisibilty(false));
  }
}

function* fetchBrandWebsiteLeads(actions: any) {
  const { pageNo = 0, sortType = 0 } = actions.data || {};

  try {
    const response = yield apiCall({
      method: "GET",
      url: BRAND_WEBSITE_API.GET_BRAND_WEBSITE_LEADS + `?page_no=${pageNo}&sort_type=${sortType}`,
      parseToJson: true,
    });
    const leadsRespponse = response?.data?.data;
    if (leadsRespponse?.status) {
      actions?.callback({
        leads: leadsRespponse?.leads,
        pages: leadsRespponse?.pages_count,
      });
    } else {
      actions?.callback({
        leads: [],
        pages: 0,
      });
      ToastNotifyError("Unable to fetch leads.");
    }
  } catch {
    actions?.callback({
      leads: [],
      pages: 0,
    });
    ToastNotifyError("Unable to fetch leads.");
  }
}

function* fetchBrandWebsiteCategoryThemes(actions: any) {
  yield put(diceLoaderVisibilty(true));
  try {
    const { id, page } = actions?.data;
    if (page === 1) {
      yield put(emptyBrandWebsiteCategoryThemes());
    }
    const response = yield apiCall({
      method: "GET",
      url: BRAND_WEBSITE_API.getCategoryThemes(id, page),
      parseToJson: true,
    });

    if (response && response?.data?.status) {
      yield put(setBrandWebsiteCategoryThemes(response?.data?.data));
    } else if (response && !response?.data?.status) {
      actions?.callback && actions?.callback();
      ToastNotifyError("Unable to fetch themes");
    }
  } catch {
    actions?.callback && actions?.callback();
    yield put(diceLoaderVisibilty(false));
    ToastNotifyError("Unable to fetch themes");
  } finally {
    yield put(diceLoaderVisibilty(false));
  }
}

function* fetchBrandWebsitePreviewTheme(actions: any) {
  yield put(diceLoaderVisibilty(true));
  try {
    const id = actions?.data;
    const response = yield apiCall({
      method: "GET",
      url: BRAND_WEBSITE_API.getPreviewThemes(id),
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      yield put(setBrandWebsitePreviewTheme(response?.data?.data));
    } else if (response && !response?.data?.status) {
      actions?.callback && actions?.callback();
      ToastNotifyError("Unable to fetch theme");
    }
  } catch {
    actions?.callback && actions?.callback();
    yield put(diceLoaderVisibilty(false));
    ToastNotifyError("Unable to fetch theme");
  } finally {
    yield put(diceLoaderVisibilty(false));
  }
}

function* setOrPurchaseBrandWebsiteTheme(actions: any) {
  try {
    yield put(diceLoaderVisibilty(true));
    const response = yield apiCall({
      method: "POST",
      url: BRAND_WEBSITE_API.SET_OR_PURCHASE_THEME,
      data: appendCreditUsedKey(actions.data),
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      yield put(authenticateUser(LocalStorageHelper.get("auth_token")));
      setPremiumPurchaseType(PREMIUM_PURCHASE_TYPE_CONSTANTS.PURCHASE_BRAND_WEBSITE_THEME);
      if (response && response?.data?.data?.is_payable) {
        setPrePaymentDataToSessionStorage(JSON.stringify(response?.data?.data?.payment_details));
        yield put(savePrePaymentData(response?.data?.data?.payment_details));
        actions.callback && actions.callback(true);
      } else {
        actions.callback && actions.callback(false, response?.data);
      }
    }
  } catch (error) {
    yield put(diceLoaderVisibilty(false));
    console.log(error);
    if (error?.data?.message) {
      ToastNotifyError(error?.data?.message);
    } else {
      ToastNotifyError("Custom Theme is applied, can't set this theme");
    }
  } finally {
    yield put(diceLoaderVisibilty(false));
  }
}

function* exportCustomerEnquiryReport(actions: any) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: BRAND_WEBSITE_API.CUSTOMER_ENQUIRY_REPORT,
      parseToJson: true,
      data: actions.data,
    });
    if (response && response?.data?.status) {
      actions.callback?.();
    } else {
      ToastNotifyError(response?.data?.message);
    }
  } catch (err) {
    err?.data?.message && ToastNotifyError(err.data.message);
  }
}

function* fetchBrandWebsiteRenewalPaymentDetails(actions: any) {
  try {
    yield put(diceLoaderVisibilty(true));
    const response = yield apiCall({
      method: "POST",
      url: BRAND_WEBSITE_API.GET_RENEWAL_PAYMENT_DETAILS,
      data: actions.data,
      parseToJson: true,
    });
    if (response?.data?.status) {
      setPrePaymentDataToSessionStorage(JSON.stringify(response?.data?.data));
      yield put(savePrePaymentData(response?.data?.data));
      actions?.callback?.();
    }
  } catch (err) {
    err?.data?.message && ToastNotifyError(err.data.message);
  } finally {
    yield put(diceLoaderVisibilty(true));
  }
}

export default function* root() {
  yield takeEvery(GET_BUSINESS_CATEGORIES, fetchBrandWebsiteBusinnessCategories);
  yield takeEvery(GET_CATEGORY_THEMES, fetchBrandWebsiteCategoryThemes);
  yield takeEvery(GET_PREVIEW_THEME, fetchBrandWebsitePreviewTheme);
  yield takeEvery(SET_OR_PURCHASE_THEME, setOrPurchaseBrandWebsiteTheme);
  yield takeEvery(EXPORT_CUSTOMER_ENQUIRY_REPORT, exportCustomerEnquiryReport);
  yield takeEvery(GET_BRAND_LEADS, fetchBrandWebsiteLeads);
  yield takeEvery(GET_RENEWAL_PAYMENT_DETAILS, fetchBrandWebsiteRenewalPaymentDetails);
}
