import checkIfItemGstDetailsExists from "@/Utils/checkIfItemGstDetailsExists";
import store from "../../../Redux/Store";
import { getStoreId } from "../../../Utils/common";
import { Item, selectedOptionObject } from "../types/bulkEditTypes";
import {
  BULK_EDIT_SAVE_TYPE,
  ITEM_AVAIALBLE_STATUS,
  STATUS,
  IS_ACTIVE_DELETE,
  COLUMN_IDS,
  IS_ACTIVE,
} from "./bulkEditConstants";

export function getPayloadForBulkEditItems(
  items: Item[],
  saveType: (typeof BULK_EDIT_SAVE_TYPE)[keyof typeof BULK_EDIT_SAVE_TYPE],
) {
  return {
    store_id: getStoreId(),
    draft_id: store.getState().bulkEditReducer.draftId,
    type: saveType,
    items: getPayloadItems(items, saveType),
  };
}

function checkForDraftCondition(item: Item, saveType: number) {
  if (saveType === BULK_EDIT_SAVE_TYPE.CATALOG) {
    return !(item?.id === 0 && item?.is_active.text === IS_ACTIVE_DELETE);
  }
  return true;
}

export function getPayloadItems(items: Item[], saveType: any) {
  const gstFeatureLock = store.getState().subscriptionReducer?.featureLocks?.gstActivated?.isLocked;
  if (items?.length) {
    const payloadItems = items.reduce((acc, item) => {
      if (item?.isEdited && checkForDraftCondition(item, saveType)) {
        acc.push({
          id: item?.id,
          store_id: getStoreId(),
          name: item?.productName,
          draft_item_id: item?.draft_item_id,
          category: {
            id: item?.category?.id,
            name: item?.category?.text,
            master_sub_category: 0,
            image_url: "",
            active_products: 0,
            selected_products: 0,
            full_category: false,
            products_config: null,
          },
          image_url: "",
          thumbnail_url: "",
          images: getImages(item),
          available: getItemAvailableFlag(item),
          description: item.description,
          discounted_price: item.discounted_price,
          price: item?.price,
          variants: getItemVariants(item?.variants, item),
          available_quantity: item?.availableQuantity,
          managed_inventory: getManageInventory(item),
          item_option_variants: item?.item_option_variants,
          variants_count: item?.variants_count,
          tags: getItemTags(item),
          low_quantity: item?.low_quantity,
          master_item_id: 0,
          is_active: getIsActive(item),
          collections: getItemCollections(item),
          brand_name: item?.brandName?.text,
          barcode: item?.barcode,
          sku_id: item?.skuId,
          tax: checkIfItemGstDetailsExists(item)
            ? {
                id: 0,
                hsn_sac_code: item?.hsn,
                gst: getItemGst(item),
              }
            : null,
        });
        return acc;
      }
      return acc;
    }, []);

    const draftItems = getDraftItemsData(payloadItems);
    const payloadItemsWithDraft = payloadItems.concat(draftItems);
    const deletedItems = getPayloadForDeletedItems();
    const payloadWithDeletedItems = payloadItemsWithDraft.concat(deletedItems);
    const payloadWithoutNewDeletedItems = removeZeroIdDeletedItems(payloadWithDeletedItems);
    return payloadWithoutNewDeletedItems;
  }
  return [];
}

function removeZeroIdDeletedItems(payloadWithDeletedItems: any[]) {
  const uniqueDraftId = {};

  const uniquePayloadWithDeletedItems = payloadWithDeletedItems?.reverse().filter((item) => {
    if (!uniqueDraftId?.[item?.draft_item_id]) {
      uniqueDraftId[item?.draft_item_id] = true;
      return true;
    }
    return false;
  });

  return uniquePayloadWithDeletedItems.filter((item) => {
    if (item.id === 0 && item?.is_active === IS_ACTIVE.DELETE) {
      return false;
    }
    return true;
  });
}

function getPayloadForDeletedItems() {
  const deletedItems = store.getState().bulkEditReducer.bulkEditDeletedItems;
  if (deletedItems?.length) {
    return deletedItems.map((item: Item) => {
      return {
        ...item,
        status: 0,
        collections: [],
        tags: [],
        store_id: getStoreId(),
        is_active: 0,
        variants: item.variants?.length
          ? item.variants.map((variant: Item) => {
              return {
                ...variant,
                collections: [],
                tags: [],
                status: 0,
              };
            })
          : [],
      };
    });
  }
  return [];
}

function getDraftItemsData(payloadItems: any) {
  const draftItems = store.getState().bulkEditReducer?.draftItems;
  if (!draftItems) {
    return [];
  }

  const payloadItemsDraftId = {};
  payloadItems.forEach((item: any) => {
    payloadItemsDraftId[item?.draft_item_id] = item;
  });
  const draftItemsNotEdited = draftItems.reduce((acc: any, curr: any) => {
    if (payloadItemsDraftId?.[curr?.draft_item_id]) {
      return acc;
    }
    acc.push(curr);
    return acc;
  }, []);

  return draftItemsNotEdited;
}

function getImageUrl(imageUrl: string) {
  if (imageUrl.includes("cdn.dot")) {
    return imageUrl;
  }
  return `https://www.youtube.com/watch?v=${imageUrl}`;
}

function getImages(item: Item) {
  if (item?.image?.selectedOptions.length) {
    const imagePayload = item?.image.selectedOptions.map((imageItem: selectedOptionObject) => {
      return {
        image_id: imageItem?.id,
        image_url: getImageUrl(imageItem?.imageUrl),
        status: 1,
        thumbnail_url: imageItem.thumbnailUrl,
        media_type: getImageUrl(imageItem?.imageUrl).includes("youtube") ? 2 : 1,
      };
    });

    return imagePayload;
  }
  return [];
}

function getItemAvailableFlag(item: Item) {
  if (item?.variants?.length) {
    let available = 0;
    item?.variants.forEach((variant: Item) => {
      available = available || getVariantAvailable(variant?.status?.text);
    });
    return available;
  }

  switch (item.status.text) {
    case STATUS.TRACK_QUANTITY: {
      if (item.availableQuantity > 0) {
        return ITEM_AVAIALBLE_STATUS.IN_STOCK;
      } else {
        return ITEM_AVAIALBLE_STATUS.OUT_OF_STOCK;
      }
    }
    case STATUS.IN_STOCK: {
      return ITEM_AVAIALBLE_STATUS.IN_STOCK;
    }
    case STATUS.OUT_OF_STOCK: {
      return ITEM_AVAIALBLE_STATUS.OUT_OF_STOCK;
    }
  }
}

function getManageInventory(item: Item) {
  if (STATUS.TRACK_QUANTITY === item?.status.text) {
    return 1;
  }
  return 0;
}

function getItemGst(item: Item) {
  const gstInString = item?.gst_rate?.text.substring(0, item?.gst_rate?.text?.length - 1);
  if (gstInString) {
    return parseFloat(gstInString);
  }
}

function getItemTags(item: Item) {
  const itemTags: any = [];
  if (item?.tags?.selectedOptions?.length) {
    item?.tags?.selectedOptions.forEach((selectedTag: selectedOptionObject) => {
      const tag = {
        id: selectedTag.id,
        name: selectedTag.text,
        is_active: 1,
        tag_color: selectedTag.color_code,
        is_custom_tag: 0,
        image_url: "",
        products_config: {},
      };
      itemTags.push(tag);
    });
  }
  return itemTags;
}

function getIsActive(item: Item) {
  if (item?.is_active?.text === "On") {
    return 1;
  }
  return 2;
}

function getItemCollections(item: Item) {
  const itemCollections: any = [];
  if (item?.collection?.selectedOptions?.length) {
    item?.collection?.selectedOptions.forEach((collectionItem: selectedOptionObject) => {
      const collection = {
        id: collectionItem?.id,
        name: collectionItem?.text,
        store_id: getStoreId(),
        image_url: collectionItem.imageUrl ?? "",
        status: "A",
      };
      itemCollections.push(collection);
    });
  }
  return itemCollections;
}

function getVariantVisibilityStatus(text: string) {
  switch (text) {
    case "On": {
      return 1;
    }
    case "Off": {
      return 2;
    }
  }
}

function getVariantAvailable(text: string) {
  switch (text) {
    case STATUS.OUT_OF_STOCK: {
      return 0;
    }
    default: {
      return 1;
    }
  }
}

function getItemVariants(variants: Item[], item: Item) {
  if (!variants) {
    variants = [];
  }

  return variants.map((variant: Item) => {
    const payloadVariant = {
      ...variant,
      images: getImages(variant),
      is_active: getIsActive(variant),
      managed_inventory: getManageInventory(item),
      available: getVariantAvailable(variant?.status?.text),
      variant_name: variant?.[COLUMN_IDS.name],
      barcode: variant?.[COLUMN_IDS.barcode].replaceAll("~", ""),
      sku_id: variant?.[COLUMN_IDS.skuId].replaceAll("~", ""),
      hsn: variant?.[COLUMN_IDS.hsn].replaceAll("~", ""),
      available_quantity: variant?.availableQuantity,
      status: getVariantVisibilityStatus(variant?.is_active?.text),
    };

    delete payloadVariant["productName"];
    delete payloadVariant["image"];
    delete payloadVariant["brandName"];
    delete payloadVariant["collections"];
    delete payloadVariant["skuId"];
    delete payloadVariant["availableQuantity"];

    return payloadVariant;
  });
}
