import { takeEvery, put } from "redux-saga/effects";
import apiCall from "../../Services/api";
import { DOMAIN_API } from "../../Services/apiUrls";
import * as actionTypes from "../ActionTypes";
import {
  saveDomainList,
  diceLoaderVisibilty,
  setPageLoaderVisibility,
  saveSearchDomainList,
  savePrePaymentData,
  saveDomainDetails,
  showDomainSuccessfulPurchaseModal,
  saveAllDomainDetails,
  saveDomainRenewalDetails,
  saveDomainCart,
  fetchDomainCart,
  saveLatestDomainPurchaseDetails,
  // saveTransformedSearchDomainList,
} from "../Actions";
import { ToastNotifyError } from "../../Components/Toast";
import history from "../../Utils/history";
import { setPremiumPurchaseType, setPrePaymentDataToSessionStorage, appendCreditUsedKey } from "../../Utils/_helper";
import { PREMIUM_PURCHASE_TYPE_CONSTANTS } from "../../Constants";
import { parseToJson } from "../../Utils/common";

function* searchDomain(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      data: actions.data,
      url: `/dotk/domain/searchDomain`,
    });
    if (response && response.data.status) {
      yield put(setPageLoaderVisibility(false));
      const payload = {
        primary_result: response.data.primary_result || {},
        results: response.data.results || [],
        searchStatus: response.data.tag_status ?? null,
      };
      yield put(saveSearchDomainList(payload));
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to Search!");
  }
}

function* searchOpenDomain(actions) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      url: `/dotk/vo1/domain/search/${actions.data.name}`,
      parseToJson: true,
    });
    if (response && response.data.status) {
      actions?.data?.callback && actions?.data?.callback(response.data.status, response?.data?.data);
      yield put(setPageLoaderVisibility(false));
      const payload = {
        primary_result: response.data.data.primary_result || {},
        results: response.data.data.results || [],
        searchStatus: response.data.data?.tag_status ?? null,
        searchedStringBE: response.data.data?.search_text || "",
      };
      yield put(saveSearchDomainList(payload));
    } else {
      actions?.data?.callback && actions?.data?.callback(response.data.status);
      ToastNotifyError(response?.data?.message);
    }
  } catch (err) {
    actions?.data?.callback && actions?.data?.callback(err?.data?.status);
    ToastNotifyError(err?.data?.message);
    yield put(setPageLoaderVisibility(false));
  }
}

function* defaultSearchDomain(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      data: actions.data,
      url: `/dotk/vm1/premium/searchDomainDefault`,
    });
    if (response && response.data.status) {
      yield put(setPageLoaderVisibility(false));
      const payload = {
        primary_result: response.data.primary_result,
        results: response.data.results,
      };
      yield put(saveDomainList(payload));
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to fetch domains!");
  }
}

function* initDomainPurchase(actions: any) {
  yield put(setPageLoaderVisibility(true));
  try {
    const response = yield apiCall({
      method: "POST",
      url: `/dotk/domain/initiateDomainPurchase`,
      data: actions.data,
    });
    if (response && response?.data?.status) {
      setPremiumPurchaseType(
        actions.data.purchase_type === 4
          ? PREMIUM_PURCHASE_TYPE_CONSTANTS.DOMAIN_MARKETING
          : PREMIUM_PURCHASE_TYPE_CONSTANTS.DOMAIN,
      );
      setPrePaymentDataToSessionStorage(JSON.stringify(response?.data?.payment));
      yield put(savePrePaymentData(response?.data?.payment));
      actions.callback && actions.callback(response?.data?.status, response?.data);
    } else {
      actions.callback && actions.callback(response?.data?.status, response?.data);
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to buy domain.  Please try again later!");
  }
}

function* getDomainDetails(actions: any) {
  yield put(setPageLoaderVisibility(true));
  try {
    const response = yield apiCall({
      method: "GET",
      url: `/dotk/vm1/premium/latestDomain`,
      data: actions.data,
    });
    if (response && response?.data?.status) {
      yield put(saveDomainDetails(response?.data?.domain));
      yield put(saveLatestDomainPurchaseDetails(response?.data?.domain));
      if (response?.data?.domain?.status < 6) {
        yield put(showDomainSuccessfulPurchaseModal(true));
      } else {
        history.push(`/domain-history`);
      }
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to fetch domain details!");
  }
}

function* initDomainSetupPurchase(actions: any) {
  yield put(setPageLoaderVisibility(true));
  try {
    const response = yield apiCall({
      method: "POST",
      url: `/dotk/vm1/premium/initiateSetupPurchase`,
      data: appendCreditUsedKey(actions.data),
    });
    if (response && response?.data?.status) {
      setPremiumPurchaseType(PREMIUM_PURCHASE_TYPE_CONSTANTS.DOMAIN_CONNECT);
      setPrePaymentDataToSessionStorage(JSON.stringify(response?.data?.payment));
      yield put(savePrePaymentData(response?.data?.payment));
      actions.callback && actions.callback(response?.data?.status, response?.data);
    } else {
      actions.callback && actions.callback(response?.data?.status, response?.data);
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err: any) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError(err?.data?.message.replaceAll("_", " "));
  }
}

function* storeDomains() {
  yield put(setPageLoaderVisibility(true));
  try {
    const response = yield apiCall({
      method: "GET",
      url: DOMAIN_API.storeDomains,
    });
    if (response && response?.data?.status) {
      yield put(saveAllDomainDetails(parseToJson(response?.data?.data)));
      yield put(setPageLoaderVisibility(false));
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
  }
}

function* domainRenewalDetails(actions: any) {
  yield put(setPageLoaderVisibility(true));
  try {
    const response = yield apiCall({
      method: "GET",
      url: DOMAIN_API.domainRenewalDetails(actions.data),
    });
    if (response && response?.data?.status) {
      yield put(saveDomainRenewalDetails(parseToJson(response?.data?.data)));
      actions?.callback && actions?.callback(response?.data?.status, response?.data);
    } else {
      actions?.callback && actions?.callback(response?.data?.status, response?.data);
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    actions?.callback && actions?.callback(false, {});
  }
}

function* initiateDomainRenewal(actions: any) {
  yield put(setPageLoaderVisibility(true));
  try {
    const response = yield apiCall({
      method: "POST",
      url: DOMAIN_API.initiateDomainRenewal,
      data: actions.data,
    });
    if (response && response?.data?.status) {
      const { DOMAIN_MARKETING, DOMAIN_CONNECT } = PREMIUM_PURCHASE_TYPE_CONSTANTS;
      setPremiumPurchaseType(actions.data.purchase_type === 4 ? DOMAIN_MARKETING : DOMAIN_CONNECT);
      setPrePaymentDataToSessionStorage(response?.data?.data);
      yield put(savePrePaymentData(parseToJson(response?.data?.data)));
      actions.callback && actions.callback(response?.data?.status, response?.data);
    } else {
      actions.callback && actions.callback(response?.data?.status, response?.data);
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to renew domain. Please try again later");
  }
}

function* fetchDomainCartInfo(actions: any) {
  yield put(setPageLoaderVisibility(true));
  try {
    const response = yield apiCall({
      method: "GET",
      url: DOMAIN_API.domainCart,
    });
    if (response && response?.data?.status) {
      const parsedResponse = parseToJson(response?.data?.data);
      const filteredItems = parsedResponse?.items?.filter((item) => item?.is_available);
      const filteredResponse = { ...parsedResponse, items: filteredItems };
      yield put(saveDomainCart(filteredResponse));
      actions?.callback && actions?.callback(response?.data?.status, filteredResponse);
    } else {
      actions?.callback && actions?.callback(response?.data?.status, response?.data);
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    actions?.callback && actions?.callback(false, {});
  }
}

function* updateDomainCart(actions: any) {
  yield put(setPageLoaderVisibility(true));
  try {
    const response = yield apiCall({
      method: "POST",
      url: DOMAIN_API.domainCart,
      data: actions.data,
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      response.data.data?.items?.map((item) => {
        if (!item?.is_available) {
          ToastNotifyError(`Error while updating cart ${item?.error_message}`);
        }
      });
      yield put(fetchDomainCart());
      actions.callback && actions.callback(response?.data?.status);
    } else {
      actions.callback && actions.callback(response?.data?.status, response?.data);
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    actions.callback && actions.callback(false);
    ToastNotifyError("Error adding domain to cart, please try again later!" + err?.data?.message);
  }
}

function* initiateDomainCartPurchase(actions: any) {
  yield put(setPageLoaderVisibility(true));
  try {
    const response = yield apiCall({
      method: "POST",
      url: DOMAIN_API.initiateDomainCartPurchase,
      data: actions.data,
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      setPremiumPurchaseType(PREMIUM_PURCHASE_TYPE_CONSTANTS.DOMAIN_SUBSCRIPTION);
      yield put(savePrePaymentData(response?.data?.data));
      setPrePaymentDataToSessionStorage(JSON.stringify(response?.data?.data));
      actions.callback && actions.callback(response?.data?.status);
    } else {
      ToastNotifyError("Unable to buy domain.  Please try again later!");
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    console.log(err);
    actions.callback && actions.callback(false);
    ToastNotifyError("Unable to buy domain - " + err?.data?.message);
  }
}

function* getLatestDomainPurchaseDetails(actions: any) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: DOMAIN_API.latestDomainDetails,
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      yield put(saveLatestDomainPurchaseDetails(response.data?.data));
      actions?.callback && actions.callback(response.data.status);
    }
  } catch (err) {
    ToastNotifyError(err?.data?.message);
    console.log(err);
  }
}

function* checkDomainAvailable(actions: any) {
  yield put(diceLoaderVisibilty(true));
  try {
    const response = yield apiCall({
      method: "GET",
      url: DOMAIN_API.checkDomainDetails(actions.data),
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      yield put(diceLoaderVisibilty(false));
      actions?.callback && actions.callback(response.data.data);
    }
  } catch (err) {
    yield put(diceLoaderVisibilty(false));
    ToastNotifyError(err?.data?.message);
  }
}

function* captureLoginMerchantLeads(actions: any) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: DOMAIN_API.captureLoginMerchantLeads,
      data: actions.data,
      parseToJson: true,
    });
    actions.callback?.(response?.data?.status);
  } catch (err) {
    console.log(err);
  }
}

export default function* root() {
  yield takeEvery(actionTypes.SEARCH_DOMAIN, searchDomain);
  yield takeEvery(actionTypes.SEARCH_OPEN_DOMAIN, searchOpenDomain);
  yield takeEvery(actionTypes.DEFAULT_SEARCH_DOMAIN, defaultSearchDomain);
  yield takeEvery(actionTypes.INITIATE_DOMAIN_PURCHASE, initDomainPurchase);
  yield takeEvery(actionTypes.GET_DOMAIN_DETAILS, getDomainDetails);
  yield takeEvery(actionTypes.INITIATE_DOMAIN_SETUP_PURCHASE, initDomainSetupPurchase);
  yield takeEvery(actionTypes.STORE_DOMAINS, storeDomains);
  yield takeEvery(actionTypes.DOMAIN_RENEWAL_DETAILS, domainRenewalDetails);
  yield takeEvery(actionTypes.INITIATE_DOMAIN_RENEWAL, initiateDomainRenewal);
  yield takeEvery(actionTypes.FETCH_DOMAIN_CART, fetchDomainCartInfo);
  yield takeEvery(actionTypes.UPDATE_DOMAIN_CART, updateDomainCart);
  yield takeEvery(actionTypes.INITIATE_DOMAIN_CART_PURCHASE, initiateDomainCartPurchase);
  yield takeEvery(actionTypes.GET_LATEST_DOMAIN_PURCHASE_DETAILS, getLatestDomainPurchaseDetails);
  yield takeEvery(actionTypes.CHECK_DOMAIN_AVAILABLE, checkDomainAvailable);
  yield takeEvery(actionTypes.CAPTURE_DLP_MERCHANT_LEADS, captureLoginMerchantLeads);
}
